import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useDidUpdateEffect from "../../../Helpers/Hooks/useDidUpdate";
import {
  InputForm,
  RadioboxForm,
  BSelect,
  DateTimePickerForm,
} from "form-builder";
import { useTranslation } from "react-i18next";
// import { onInputResetAction } from "../../../Store/Actions";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Const from "../../../Constants";

// Icons
import ScheduleIcon from "@mui/icons-material/Schedule";
import Constants from "../../../Constants";
import { onInputResetAction } from "../../../Store/Actions";
import {
  Requests,
  WorkPlaces,
  Compensation,
  WorkOnDaysOff,
} from "../../../Components/SharedFormSections/DayOffException";
import { useLazyQuery } from "@apollo/client";
import { attendanceTypeConfigOptionsQuery } from "../../../Graphql/query";
import Privilages from "../../../Constants/Privilages";

import { checkCompanyPrivileges } from "../../../Helpers/HelperFns";
import MultipleCheckIn from "../../../Components/SharedFormSections/MultipleCheckIn";
import CheckInSettings from "../../../Components/CheckInSettings";
import WorkOnHolidays from "../../../Components/SharedFormSections/WorkOnHolidays";
import _ from "lodash";

const WorkScheduleSection = ({
  isEditable,
  FormProps,
  attProfileHasAnnualLeaves,
  attProfileHasSickLeave,
  attProfileHasEmergencyLeave,
  leaveAndBreakProfileHasAnnualLeaves,
  leaveAndBreakProfileHasEmergencyLeaves,
  leaveAndBreakProfileHasSickLeaves,
}) => {
  const { t } = useTranslation();

  const isCompanyHasShift = checkCompanyPrivileges({
    privileges: [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
  });

  //   Redux hooks
  const dispatch = useDispatch();
  const normalWorkTiming = useSelector(
    (state) => state?.super?.editEmployeeFormData?.normalWorkTiming
  );
  const firstHalfWorkTiming = useSelector(
    (state) => state?.super?.editEmployeeFormData?.firstHalfWorkTiming
  );
  const secondHalfWorkTiming = useSelector(
    (state) => state?.super?.editEmployeeFormData?.secondHalfWorkTiming
  );
  const attendance_profiles = useSelector(
    (state) => state?.super?.editEmployeeFormData?.attendance_profiles
  );
  const isEditing = useSelector((state) => state?.super?.editEmployeeForm);
  const employeeForm = useSelector(
    (state) => state?.super?.[FormProps?.formName]
  );
  const leavesBreakOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.leavesBreaks
  );
  const workGroupOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.workGroups
  );

  const workTeamOptions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.workTeams
  );

  const showAnnualLeavesSection =
    (employeeForm?.attendanceType === Constants.attendanceTypes.OFFICE_BASED &&
      attProfileHasAnnualLeaves) ||
    (employeeForm?.attendanceType === Constants.attendanceTypes.SHIFT_BASED &&
      leaveAndBreakProfileHasAnnualLeaves);

  const showEmergencyLeavesSection =
    (employeeForm?.attendanceType === Constants.attendanceTypes.OFFICE_BASED &&
      attProfileHasEmergencyLeave) ||
    (employeeForm?.attendanceType === Constants.attendanceTypes.SHIFT_BASED &&
      leaveAndBreakProfileHasEmergencyLeaves);

  const showSickLeavesSection =
    (employeeForm?.attendanceType === Constants.attendanceTypes.OFFICE_BASED &&
      attProfileHasSickLeave) ||
    (employeeForm?.attendanceType === Constants.attendanceTypes.SHIFT_BASED &&
      leaveAndBreakProfileHasSickLeaves);

  const handleInterceptAttendanceProfileChange = () => {
    dispatch(onInputResetAction(FormProps?.formName, "home_days"));
  };

  const [getOptions, { data }] = useLazyQuery(attendanceTypeConfigOptionsQuery);

  /* ↓ State Effects ↓ */

  useDidUpdateEffect(() => {
    if (
      !employeeForm?.allow_work_on_day_off ||
      employeeForm?.attendanceType !== Constants.attendanceTypes.SHIFT_BASED
    ) {
      dispatch(onInputResetAction(FormProps.formName, "allow_overtime"));
      dispatch(onInputResetAction(FormProps.formName, "allow_permission"));
      dispatch(onInputResetAction(FormProps.formName, "apply_compensation"));
      dispatch(onInputResetAction(FormProps.formName, "allow_work_on_day_off"));
    }
  }, [employeeForm?.attendanceType, employeeForm?.allow_work_on_day_off]);

  useEffect(() => {
    employeeForm?.attendanceType === Constants.attendanceTypes.SHIFT_BASED &&
      getOptions({ variables: { isOffice: false } });
  }, [employeeForm?.attendanceType]);

  const checkInsWorkPlaces = useSelector(
    (state) => state.super?.employeeFormCheckInsWorkPlaces?.data
  );

  // start of handle work time options consist of worktime menu data and old selected work time data
  const serilazedNormalWorkTimingArray = [
    data?.normal_work_timings ?? [],
    normalWorkTiming ?? [],
  ].flat();

  const serilazedHalfWorkTimingArray = [
    data?.half_work_timings ?? [],
    [
      firstHalfWorkTiming ?? {},
      secondHalfWorkTiming ?? {},
    ] ?? [],
  ].flat();

  const normalWorkTimeOptions = _.uniqBy(serilazedNormalWorkTimingArray, "id");
  const halfWorkTimeOptions = _.uniqBy(serilazedHalfWorkTimingArray, "id");
  // End of handle work time options consist of worktime menu data and old selected work time data

  return (
    <div className="boxContainer">
      <div className="name_container">
        <ScheduleIcon />
        <h4 className="sub-title-style mb-0">{t("work schedule")}</h4>
      </div>
      <hr />
      <RadioboxForm
        {...FormProps}
        options={[
          {
            label: "Office Based Hours",
            value: "office based",
            optProps: {
              disabled: !isEditable,
            },
          },
          {
            label: "shift based work schedule",
            value: "shifts",
            optProps: {
              disabled: !isCompanyHasShift || !isEditable,
            },
          },
        ]}
        label="Employee Work Schedule"
        name="attendanceType"
        type="radio"
        containerStyle="mt-3"
        labelStyle="label-style"
        optionsContainerStyle="optionsContainerStyle row"
        optionItemStyle="col-6 px-0"
        optionInputStyle=" "
        optionLabelStyle="mb-0 mr-3"
      />
      {employeeForm?.attendanceType ===
      Constants.attendanceTypes.OFFICE_BASED ? (
        <>
          <div className="mt-3">
            <BSelect
              {...FormProps}
              name="att_profile_id"
              label={t("attendance profile")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={attendance_profiles}
              validateBy="textRequired"
              validationName="input.user_input.att_profile_id"
              skipLocalization
              icon="person"
              isDisabled={!isEditable}
              onInterceptInputOnChange={handleInterceptAttendanceProfileChange}
            />
          </div>
        </>
      ) : null}
      {employeeForm?.attendanceType ===
      Constants.attendanceTypes.SHIFT_BASED ? (
        <div className="row">
          <div className="mt-3 col-lg-6">
            <BSelect
              {...FormProps}
              name="permissionLeavesBreakSettingId"
              label={t("leave and break profile")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={leavesBreakOptions}
              validateBy="textRequired"
              validationName="input.user_input.permissionLeavesBreakSettingId"
              skipLocalization
              icon="person"
              isDisabled={!isEditable}
            />
          </div>

          <div className="mt-3 col-lg-6">
            <BSelect
              {...FormProps}
              name="workGroupId"
              label={t("work group")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={workGroupOptions}
              validationName="input.user_input.attendanceType.workGroupId"
              skipLocalization
              icon="person"
              isClearable
              isDisabled={!isEditable}
              validateBy="textRequired"
            />
          </div>

          <div className="mt-2 col-lg-6">
            <BSelect
              {...FormProps}
              name="workTeamId"
              label={t("work team")}
              keepDefaultStyle
              placeholder={t("select option")}
              options={workTeamOptions?.filter(
                (workteam) =>
                  workteam?.workGroup?.id === employeeForm?.workGroupId
              )}
              validationName="input.user_input.workTeamId"
              skipLocalization
              icon="person"
              isDisabled={!isEditable}
            />
          </div>

          <div className="col-6 shift_start_at">
            <DateTimePickerForm
              {...FormProps}
              label={t("join team from")}
              labelStyle="mb-2"
              name="workTeamStartDate"
              containerStyle="flex-column"
              placeholder={t("select date")}
              validationName="input.user_input.attendanceType.joiningFrom"
              disabled={!isEditable}
            />
          </div>

          <div className="col-lg-12 mt-2">
            <BSelect
              {...FormProps}
              label={t("first day of the week")}
              name="first_day_of_the_week"
              keepDefaultStyle
              placeholder={t("select option")}
              hideSelectedOptions
              optionLabel="label"
              optionValue="value"
              options={Const.WeekDays}
              containerStyle="row justify-content-between align-items-center my-3"
              labelStyle="col-12 mb-2"
              inputContainerStyle="col-12"
              validationName="input.user_input.weekStartDay"
              validateBy={
                employeeForm?.attendanceType ===
                Constants.attendanceTypes.SHIFT_BASED
                  ? "textRequired"
                  : false
              }
              icon={<CalendarToday />}
              change
              isDisabled={!isEditable}
            />
          </div>

          <div className="col-lg-12 mt-2">
            <MultipleCheckIn
              isShifts
              formProps={FormProps}
              name="allowMultipleCheckIns"
              allow_customize_check_ins={
                employeeForm?.allow_customize_check_ins
              }
              customCheckFormIDs={{
                in: employeeForm?.check_in_form_id,
                out: employeeForm?.check_out_form_id,
              }}
            />
          </div>
        </div>
      ) : null}

      {employeeForm?.attendanceType ===
      Constants.attendanceTypes.SHIFT_BASED ? (
        <>
          {/* check in settings start */}
          {employeeForm?.allowMultipleCheckIns ? (
            <CheckInSettings
              reducer="super"
              formProps={FormProps}
              workPlacesOptions={checkInsWorkPlaces}
            />
          ) : null}
          {/* check in settings end */}

          {/* Work On Days Off */}
          <WorkOnDaysOff
            formProps={FormProps}
            serverValidationPrefix="user_input.attendanceType."
            halfWorkTimingOptions={halfWorkTimeOptions}
            normalWorkTimingOptions={normalWorkTimeOptions}
          />

          <div className="ml-3">
            {employeeForm?.allow_work_on_day_off ? (
              <>
                {/* Work Places */}
                <WorkPlaces
                  formProps={FormProps}
                  locationsOptions={data?.offices?.data}
                  serverValidationPrefix="user_input.attendanceType."
                />

                {!employeeForm?.treat_as_normal ? (
                  <>
                    {/* Compensation */}
                    <Compensation
                      formProps={FormProps}
                      serverValidationPrefix="user_input.attendanceType."
                      compensation_type={employeeForm?.compensation_type}
                      apply_compensation={employeeForm?.apply_compensation}
                    />

                    {/* Requests */}
                    <Requests formProps={FormProps} />
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          <WorkOnHolidays
            halfWorkTimingOptions={halfWorkTimeOptions}
            normalWorkTimingOptions={normalWorkTimeOptions}
            formProps={FormProps}
            locationsOptions={data?.offices?.data}
            showWorkPlaceSection={true}
            serverValidationPrefix="input.user_input.attendanceType"
          />
        </>
      ) : null}
      {!isEditing && (
        <div className="row align-items-center mt-3">
          {showAnnualLeavesSection ||
          showEmergencyLeavesSection ||
          showSickLeavesSection ? (
            <div className="col-12 mb-2">
              <label className="font-weight-bold sub_title">
                {t("beginning leave balance")}
              </label>
            </div>
          ) : null}

          {showAnnualLeavesSection ? (
            <div className="leaves_field col-xl-4">
              <label>{t("annual leaves")}</label>
              <InputForm
                {...FormProps}
                name="annual_leaves"
                validateBy="textRequired"
                validationName="input.user_input.beginning_balance.annual_leaves"
                type="number"
                hasSuffix
                suffixTitle="days"
                rootStyle="mx-xl-4"
                inputContainerStyle="input-container-style-default w-100"
              />
            </div>
          ) : null}

          {showEmergencyLeavesSection ? (
            <div className="leaves_field col-xl-4">
              <label>{t("emergency credit")}</label>
              <InputForm
                {...FormProps}
                name="emergency_leaves"
                validateBy="textRequired"
                validationName="input.user_input.beginning_balance.emergency_leaves"
                type="number"
                hasSuffix
                suffixTitle="days"
                rootStyle="mx-xl-4"
                inputContainerStyle="input-container-style-default w-100"
              />
            </div>
          ) : null}

          {showSickLeavesSection ? (
            <div className="leaves_field col-xl-4">
              <label>{t("sick leaves")}</label>
              <InputForm
                {...FormProps}
                name="sick_leaves"
                validationName="input.user_input.beginning_balance.sick_leaves"
                type="number"
                hasSuffix
                suffixTitle="days"
                rootStyle="mx-xl-4"
                inputContainerStyle="input-container-style-default w-100"
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default WorkScheduleSection;
