import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Helper from "../../Helpers/HelperFns";
import { Link } from "react-router-dom";
import {
  PlayCircleOutline,
  PauseCircleOutline,
  DeleteForever,
  VpnKey,
  Home,
  ArrowDownward,
  ArrowUpward,
  Login,
  Logout,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons";
import {
  toggleActivationModal,
  addEmployeeIdToActivation,
  editEmployeeAction,
  showManagePrivilagesModalAction,
  editEmployeePrivilegesAction,
  resendCredentailsAction,
  fetchInitDeleteManagerAction,
  fetchInitSuspendManagerAction,
  toggleSetTemporaryPasswordModal,
} from "../../Store/Actions";
import { connect, useDispatch, useSelector } from "react-redux";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import InfoTooltip from "../../Containers/EmployeeProfile/InfoTooltip";
import RenderMultipleSignIns from "../RenderMultipleSignIns";
import { Badge, Box, Card, Divider, Typography } from "@mui/material";
import IconButtonWithTooltip, {
  EditIconButton,
} from "../IconButtonWithTooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const HrEmployeeCard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canAddNewEmployee = useSelector(
    (state) => state?.super?.allEmployeesList?.total
  );
  const planId = useSelector(
    (state) => state.auth?.userProfile?.company?.activeSubscription?.plan?.id
  );
  const canSignOnBehalf = HelperFns.checkPrivileges({
    allowBP: true,
    privileges: [Privilages.SIGN_IN_OUT_ON_BEHALF_OF_EMPLOYEES],
  });
  // To target the uploader btn to upload employee image
  // function UploaderHandleClick() {
  //     document.getElementById('qu-input-image-uploader').click();
  // }

  /* ↓ helpers ↓ */

  // handle render component
  const shouldRenderComponent = (reqirePrivileges) => {
    if (
      (HelperFns.checkPrivileges({
        allowBP: false,
        scope: "managed",
        privileges: reqirePrivileges,
      }) &&
        HelperFns.isManagedByAuth(
          props?.manager?.id,
          props?.copied_managers?.map((user) => user?.id)
        )) ||
      HelperFns.checkPrivileges({
        allowBP: true,
        scope: "all",
        privileges: reqirePrivileges,
      })
    ) {
      return true;
    }
    return false;
  };

  const handleSuspensionBtn = () => {
    props.fetchInitSuspendManagerAction(props?.id);
  };

  const handleActivationBtn = () => {
    props.toggleActivationModal(
      props?.attendance_profile?.permission_leaves_break_setting
        ?.sickLeaveSetting?.sick_leaves,
      props?.attendance_profile?.activeWorkTiming?.overtime_permissible,
      props?.attendance_profile?.permission_leaves_break_setting
        ?.annualLeaveSetting?.allowed_emergency_leaves,
      props?.office?.country?.id
    );
    props.addEmployeeIdToActivation(
      props.id,
      props?.office,
      props?.office?.country?.id
    );
  };

  const handleDeleteUser = () => {
    props.fetchInitDeleteManagerAction(props.id);
  };

  const handleEditBtn = () => {
    props.editEmployeeAction(props.id);
  };

  // for resend credentails action
  const handleResendCredentials = () => {
    props.resendCredentailsAction(props?.id);
  };

  const handleSetTemporaryPassword = () => {
    dispatch(toggleSetTemporaryPasswordModal({ isOpen: true, user: props }));
  };

  // for manage privilages modal
  const handleManagePrivilagesModal = () => {
    props.editEmployeePrivilegesAction(props.id, planId);
    // props.showManagePrivilagesModalAction({
    //   ref: "managePrivilagesModalActions",
    //   formName: "managePrivilagesModal",
    // });
  };

  const emp_multiple_sign_ins = useSelector(
    (state) =>
      state?.super?.allEmployeesList?.employeesDetails?.find(
        (emp) => emp?.emp_id == props?.id
      )?.details ?? []
  );

  const getCurrentSignIn = () => {
    if (emp_multiple_sign_ins?.length) {
      let currentSignIn = emp_multiple_sign_ins?.find(
        (signIn) =>
          (!!signIn?.sign_in_time &&
            !!!signIn?.sign_out_time &&
            !!signIn?.status) ||
          !!signIn?.status
      );
      if (currentSignIn) {
        return currentSignIn;
      } else {
        return emp_multiple_sign_ins[0];
      }
    } else {
      return null;
    }
  };

  const handleOpenSignOnBehalfModal = (isIn) => {
    const { name, id } = props;
    const { date, ...data } = emp_multiple_sign_ins?.[0] || {};

    props?.openSignOnBehalfModal({
      ...data,
      selectedDate: date,
      name,
      id,
      isIn,
    });
  };

  return (
    <Card
      component="article"
      variant="outlined"
      sx={{
        width: 1,
        height: 130,
        display: "flex",
        border: "1px solid #eaeef0",
        justifyContent: "space-between",
        boxShadow: "0 3px 15px 0 rgba(0,0,0,.09)",
        bgcolor: props?.isDimmed ? "#f3f3f3" : "#fff",
      }}
    >
      {/* Image Side */}
      <Box
        className="employee-img"
        sx={{
          py: 0.5,
          minWidth: 80,
          display: "grid",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <Avatar
          src={props?.profile_picture?.path ?? props?.face?.path}
          className={
            +props?.on_break
              ? "avatar blackish-avatar on_break_status_style"
              : "avatar blackish-avatar"
          }
        >
          {Helper.getIntials(props?.name)}
        </Avatar>

        {!props?.isTeamView ? (
          <div className="d-flex employee_card_btns_wrapper_style mt-1">
            <InfoTooltip data={props} />
          </div>
        ) : null}
      </Box>

      <Divider light flexItem orientation="vertical" sx={{ my: 1 }} />

      {/* Text Side */}
      <Box
        sx={{
          pb: 1,
          pt: 0.75,
          width: "80%",
          display: "grid",
          marginInlineStart: 0.5,
        }}
      >
        <Box sx={{ width: "95%", overflow: "hidden" }}>
          {/* Name */}
          <HasPrivileges
            reqireMain={[
              Privilages.VIEW_EMPLOYEE_PROFILES,
              Privilages.VIEW_EMPLOYEE_SCHEDULES,
              Privilages.VIEW_EMPLOYEE_DOCUMENTS,
              Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
              Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
              Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
            ]}
            allowBP
            altChildren={
              <Typography
                noWrap
                component="h2"
                sx={{ textAlign: "left" }}
                className="employee_name_style"
                dir={HelperFns.isStringRTL(props?.name) ? "rtl" : "ltr"}
              >
                {props?.name?.toLowerCase()}
              </Typography>
            }
          >
            <Tooltip
              classes={{
                tooltip: "custom_tooltip_style",
                arrow: "custom_arrow_tooltip_style",
                popper: "custom_employee_name_tooltip_style",
              }}
              arrow
              title={
                props?.name?.length > 20 ? (
                  <h4 className="employee_name_tooltip_style">
                    {props?.name?.toLowerCase()}
                  </h4>
                ) : (
                  ""
                )
              }
            >
              <Typography
                noWrap
                component="h2"
                sx={{ textAlign: "left" }}
                dir={HelperFns.isStringRTL(props?.name) ? "rtl" : "ltr"}
              >
                {props?.user_type === "Owner" ? (
                  props?.name?.toLowerCase()
                ) : (
                  <Link
                    className="employee_name_style"
                    to={`/employees/employee-profile/${props.id}`}
                  >
                    {props?.name?.toLowerCase()}
                  </Link>
                )}
              </Typography>
            </Tooltip>
          </HasPrivileges>

          {/* Position */}
          <Tooltip
            classes={{
              tooltip: "custom_tooltip_style",
              arrow: "custom_arrow_tooltip_style",
              popper: "custom_employee_name_tooltip_style",
            }}
            arrow
            title={
              props?.position?.name.length > 20 ? (
                <Typography variant="body2" color="text.secondary">
                  {props?.user_type === "Owner"
                    ? props?.user_type
                    : props?.position?.name}
                </Typography>
              ) : null
            }
          >
            <Typography component="div" variant="body2" color="text.secondary">
              {props?.user_type === "Owner"
                ? props?.user_type
                : props?.position?.name}

              {/* Department */}
              <Tooltip
                classes={{
                  tooltip: "custom_tooltip_style",
                  arrow: "custom_arrow_tooltip_style",
                  popper: "custom_employee_name_tooltip_style",
                }}
                arrow
                title={
                  props?.department?.name.length > 25 ? (
                    <div className="employee_title_style">
                      {props?.department?.name}
                    </div>
                  ) : null
                }
              >
                <div className="employee_title_style">
                  {props?.department?.name}
                </div>
              </Tooltip>
            </Typography>
          </Tooltip>
        </Box>

        {/* Lower Part */}
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
          }}
        >
          {/* Attendance */}
          {getCurrentSignIn() !== null ? (
            <div
              className={`d-flex ${
                emp_multiple_sign_ins?.length > 1 ||
                (!getCurrentSignIn()?.sign_in_time &&
                  getCurrentSignIn()?.sign_in_req !== null)
                  ? ""
                  : "employee-sign-ins-container"
              } ${
                props.active
                  ? `${getCurrentSignIn()?.status}_status_style`
                  : `${
                      props.is_blacklisted
                        ? "blacklist_status_style"
                        : "suspend_status_style"
                    }`
              }`}
            >
              <Tooltip
                title={
                  props.is_blacklisted
                    ? "Blacklisted Employee"
                    : getCurrentSignIn()?.work_timing_name
                }
                className="d-flex align-items-center"
              >
                {getCurrentSignIn()?.sign_in_time ? (
                  <div className="d-flex align-items-center">
                    {+getCurrentSignIn()?.from_home ? (
                      <Home
                        sx={{
                          fontSize: 12,
                          color: "#313030",
                          marginInlineEnd: 0.25,
                        }}
                      />
                    ) : null}
                    <div
                      style={{
                        fontSize: emp_multiple_sign_ins?.length > 1 ? 10 : 11,
                      }}
                    >
                      <ArrowDownward sx={{ fontSize: 11, color: "#27b40c" }} />
                      {getCurrentSignIn()?.sign_in_time
                        ? moment(getCurrentSignIn()?.sign_in_time).format(
                            "hh:mm A"
                          )
                        : " _ _ : _ _"}
                      <span className="px-1">-</span>
                      {canSignOnBehalf &&
                      emp_multiple_sign_ins?.length === 1 &&
                      !getCurrentSignIn()?.sign_out_time ? null : (
                        <>
                          <ArrowUpward
                            sx={{ fontSize: 11, color: "#ff6a6a" }}
                          />
                          {getCurrentSignIn()?.sign_out_time
                            ? moment(getCurrentSignIn()?.sign_out_time).format(
                                "hh:mm A"
                              )
                            : "_ _ : _ _"}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    {props?.user_type === "Owner" ||
                    (props.active &&
                      !getCurrentSignIn()?.sign_in_req &&
                      getCurrentSignIn()?.sign_in_req !== null)
                      ? t("present")
                      : props.active !== undefined
                      ? props.active
                        ? t(getCurrentSignIn()?.status?.toLowerCase())
                        : t("suspended")
                      : ""}
                  </div>
                )}
              </Tooltip>
            </div>
          ) : (
            <p>{t("not signed in")}</p>
          )}

          {/* Sign in/out on behalf */}
          <div>
            {emp_multiple_sign_ins?.length > 1 ? (
              <div className="dropdown-icon-container mb-1">
                <Tooltip
                  classes={{
                    arrow: "custom_arrow_tooltip_style",
                    tooltip: "custom-employee-card-tooltip p-0",
                    popper: "custom_employee_name_tooltip_style",
                    // tooltip: "custom_tooltip_style p-0",
                    // arrow: "custom_arrow_tooltip_style",
                  }}
                  arrow
                  color="#333"
                  // open={true}
                  title={
                    <div>
                      {emp_multiple_sign_ins?.map((signIn, index) => (
                        <RenderMultipleSignIns
                          empList
                          index={index}
                          signIn={signIn}
                          signInTime={getCurrentSignIn()?.sign_in_time}
                          emp={{ id: props?.id, name: props?.name }}
                          openSignOnBehalfModal={props?.openSignOnBehalfModal}
                        />
                      ))}
                    </div>
                  }
                >
                  <Badge
                    color="success"
                    badgeContent={emp_multiple_sign_ins?.length}
                  >
                    <KeyboardArrowDownIcon
                      className="dropdown-icon"
                      fontSize="large"
                    />
                  </Badge>
                </Tooltip>
              </div>
            ) : getCurrentSignIn()?.sign_in_time ? (
              getCurrentSignIn()?.sign_in_req &&
              canSignOnBehalf &&
              !getCurrentSignIn()?.sign_out_time ? (
                <IconButtonWithTooltip
                  label="sign out"
                  icon={<Logout sx={{ color: "#ff6a6a", fontSize: 18 }} />}
                  onClick={() => handleOpenSignOnBehalfModal(false)}
                />
              ) : null
            ) : getCurrentSignIn()?.sign_in_req &&
              canSignOnBehalf &&
              !!!getCurrentSignIn()?.status ? (
              <IconButtonWithTooltip
                label="sign in"
                icon={<Login fontSize="small" sx={{ color: "#27b40c" }} />}
                onClick={() => handleOpenSignOnBehalfModal(true)}
              />
            ) : null}
          </div>

          {/* Actions */}
          {shouldRenderComponent([Privilages.VIEW_EMPLOYEES]) &&
            !props?.isTeamView && (
              <Box
                sx={{ display: "flex" }}
                className="hr_employee_icons_wrapper_style emp-card ml-auto"
              >
                {/* Edit */}
                <HasPrivileges
                  reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <EditIconButton
                      iconProps={{
                        color: "inherit",
                        sx: { fontSize: 16 },
                        className: "edit-icon-style",
                      }}
                    />
                  }
                >
                  {props?.user_type ===
                  "Owner" ? null : !props?.checkPassword &&
                    props?.has_credentials ? (
                    <IconButtonWithTooltip
                      label="Send activation message"
                      onClick={handleResendCredentials}
                      icon={
                        <VpnKey
                          sx={{ fontSize: 16 }}
                          className="resend-icon-style"
                        />
                      }
                    />
                  ) : (
                    <IconButtonWithTooltip
                      label="Set temporary password"
                      onClick={handleSetTemporaryPassword}
                      icon={
                        <VpnKey
                          sx={{ fontSize: 16 }}
                          className="set-temporary-password-icon-style"
                        />
                      }
                    />
                  )}

                  {props?.user_type === "Owner" ? (
                    ""
                  ) : (
                    <EditIconButton
                      onClick={handleEditBtn}
                      iconProps={{
                        color: "inherit",
                        sx: { fontSize: 16 },
                        className: "edit-icon-style",
                      }}
                    />
                  )}
                </HasPrivileges>

                {/* Activate - Suspense */}
                <HasPrivileges
                  reqireMain={[Privilages.ACTIVATE_SUSPEND_EMPLOYEES]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    props?.user_type === "Owner" ? (
                      ""
                    ) : props.active ? (
                      <PauseCircleOutline
                        sx={{ fontSize: 16 }}
                        className="pause-icon-style"
                      />
                    ) : (
                      <PlayCircleOutline
                        sx={{ fontSize: 16 }}
                        className="play-icon-style"
                      />
                    )
                  }
                >
                  {props?.user_type === "Owner" ||
                  props?.saas?.primaryCompaniesCount > 0 ? (
                    ""
                  ) : props.active ? (
                    <IconButtonWithTooltip
                      label="Suspension"
                      onClick={handleSuspensionBtn}
                      icon={
                        <PauseCircleOutline
                          sx={{ fontSize: 16 }}
                          className="pause-icon-style"
                        />
                      }
                    />
                  ) : (
                    <IconButtonWithTooltip
                      label="activate"
                      onClick={handleActivationBtn}
                      disabled={!canAddNewEmployee}
                      disabledLabel="you have reached the maximum number of employees"
                      icon={
                        <PlayCircleOutline
                          sx={{ fontSize: 16 }}
                          className="play-icon-style"
                        />
                      }
                    />
                  )}
                </HasPrivileges>

                {/* Delete */}
                <HasPrivileges
                  reqireMain={[Privilages.ADD_EDIT_DELETE_EMPLOYEES]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <IconButtonWithTooltip
                      label="remove"
                      onClick={handleDeleteUser}
                      icon={
                        <DeleteForever
                          color="inherit"
                          sx={{ fontSize: 16 }}
                          className="delete-icon-style"
                        />
                      }
                    />
                  }
                >
                  {props?.user_type === "Owner" ||
                  props?.saas?.primaryCompaniesCount > 0 ? null : (
                    <IconButtonWithTooltip
                      label="remove"
                      onClick={handleDeleteUser}
                      icon={
                        <DeleteForever
                          color="inherit"
                          sx={{ fontSize: 16 }}
                          className="delete-icon-style"
                        />
                      }
                    />
                  )}
                </HasPrivileges>

                {/* "Manage Privileges" */}
                <HasPrivileges
                  reqireMain={[Privilages.EDIT_USER_PRIVILEGE]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    props?.user_type === "Owner" ? null : (
                      <span>
                        <FontAwesomeIcon
                          size="xs"
                          icon={faLockOpen}
                          className="manage-icon-style"
                        />
                      </span>
                    )
                  }
                >
                  {props?.user_type === "Owner" ? null : (
                    <IconButtonWithTooltip
                      label="Manage privileges"
                      onClick={handleManagePrivilagesModal}
                      icon={
                        <FontAwesomeIcon
                          size="xs"
                          icon={faLockOpen}
                          className="manage-icon-style"
                        />
                      }
                    />
                  )}
                </HasPrivileges>
              </Box>
            )}
        </Box>
      </Box>
    </Card>
  );
};

export default connect(null, {
  toggleActivationModal,
  addEmployeeIdToActivation,
  editEmployeeAction,
  showManagePrivilagesModalAction,
  editEmployeePrivilegesAction,
  resendCredentailsAction,
  fetchInitDeleteManagerAction,
  fetchInitSuspendManagerAction,
})(HrEmployeeCard);
