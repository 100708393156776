import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepConnector } from "@mui/material";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    borderRadius: "3px",
    display: "flex",
    flex: 1,
    padding: "12.5px 0px 12.5px",
    backgroundColor: "#fff",
  },
  alternativeLabel: {
    borderRadius: "3px",
    backgroundColor: "#ffffff",
    overflow: "auto",
  },
  iconContainer: {
    // define styles for icon container
    transform: "scale(1.2)",
  },
  alternativeLabelConnector: {
    top: "20px",
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  line: {
    backgroundColor: "#23aaeb",
  },
  label: {
    fontSize: "13px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.54,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#4d4f5c",
  },
  completed: {
    color: "#23aaeb !Important",
    "& $line": {
      borderColor: "#784af4",
    },
  },
  active: {
    color: "#23aaeb !Important",
    ".MuiStepIcon-text": { fill: "#fff !Important" },
    "& $line": {
      borderColor: "#784af4",
    },
  },
  errorCompleted: {
    color: "red !Important",
  },
  errorActive: {
    color: "red !Important",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const WORK_TIMING_FIRST_STEP = [
  "input.name",
  "input.sign_in_start_time",
  "input.sign_in_end_time",
  "input.work_hours",
  "input.end_of_day",
  "input.time_allowed_before_sign_in",

  "input.late_reset_period",
  "input.late_tardiness_penalty_type",
  "input.max_lateness_permissibility",
  "input.minor_tardiness_range",
  "input.minor_violations",
  "input.major_tardiness_range",
  "input.major_tardiness_range1",
  "input.major_tardiness_range2",
  "input.major_tardiness_range3",
  "input.major_tardiness_range4",
  "input.major_tardiness_range5",
  "input.major_tardiness_range6",
  "input.major_tardiness_range7",
  "input.major_tardiness_range8",
  "input.major_tardiness_range9",
  "input.major_tardiness_range10",
  "input.major_violations",
  "input.major_violations1",
  "input.major_violations2",
  "input.major_violations3",
  "input.major_violations4",
  "input.major_violations5",
  "input.major_violations6",
  "input.major_violations7",
  "input.major_violations8",
  "input.major_violations9",
  "input.major_violations10",
  "input.skip_not_applied_minor_penalties",
  "input.skip_not_applied_major_penalties",
  "input.skip_not_applied_major_penalties1",
  "input.skip_not_applied_major_penalties2",
  "input.skip_not_applied_major_penalties3",
  "input.skip_not_applied_major_penalties4",
  "input.skip_not_applied_major_penalties5",
  "input.skip_not_applied_major_penalties6",
  "input.skip_not_applied_major_penalties7",
  "input.skip_not_applied_major_penalties8",
  "input.skip_not_applied_major_penalties9",
  "input.skip_not_applied_major_penalties10",

  "input.absent_reset_period",
  "input.emergency_penalty_type",
  "input.absent_violations",
  "input.skip_not_applied_absent_penalties",
  "input.can_not_sign_out_before",
  "input.can_not_sign_in_after",
  "input.calc_sign_out_time_from_actual_sign_in",

  "input.tardiness_type_sign_in",
  "input.annual_leave_hours_sign_in",
  "input.consider_permissibility_hours",
];

const WORK_TIMING_SECOND_STEP = [
  "input.allow_early_signout",
  "input.early_signout_reset_period",
  "input.early_signout_deduction_type",
  "input.early_sign_out_violations",
  "input.skip_not_applied_early_sign_out_penalties",

  "input.apply_missing_sign_out",
  "input.missing_sign_out_reset_period",
  "input.missing_sign_out_deduction_type",
  "input.missing_sign_out_violations",

  "input.apply_missing_sign_out",
  "input.missing_sign_out_reset_period",
  "input.skip_not_applied_missing_sign_out_penalties",

  "input.auto_sign_out",
  "input.auto_sign_out_time",

  "input.absent_early_sign_out",
  "input.sign_out_cuttoff_time",

  "input.min_overtime_cuttoff",
  // "input.max_overtime_per_month",
  "input.max_overtime_per_day",

  "input.apply_tardiness_sign_out",
  "input.annual_leave_hours_sign_out",
];

const WORK_TIMING_THIRD_STEP = [
  "input.sign_in_reminder_time",
  "input.sign_out_reminder",
  "input.sign_out_reminder_time",
  "input.min_work_hours",
];

// for break step
const WORK_TIMING_FOURTH_STEP = [
  "input.override_break_setting",
  "input.break_work_timing_setting",
  "input.allow_break",
  "input.deduct_from_work_hours",
  "input.break_type",
  "input.break_interval",
  "input.start_break",
  "input.can_end_break",
  "input.allow_limit_total_break",
  "input.number_of_allowed_break_sessions_per_day",
  "input.maximum_break_session_duration",
  "input.maximum_break_duration",
];

const WorkTimingsTab = (props) => {
  const { classes } = useStyles();
  let serverValidationKeys = [];

  const handleServerValidationStepRedirection = () => {
    serverValidationKeys = Object.keys(props.workTimingsServerValidation);
    if (
      WORK_TIMING_FIRST_STEP.some((input) =>
        serverValidationKeys.includes(input)
      )
    ) {
      props.onChangeStep(0);
      return;
    }
    if (
      WORK_TIMING_SECOND_STEP.some((input) =>
        serverValidationKeys.includes(input)
      )
    ) {
      props.onChangeStep(2);
      return;
    }
    if (
      WORK_TIMING_THIRD_STEP.some((input) =>
        serverValidationKeys.includes(input)
      )
    ) {
      props.onChangeStep(3);
      return;
    }
    if (
      WORK_TIMING_FOURTH_STEP.some((input) =>
        serverValidationKeys.includes(input)
      )
    ) {
      props.onChangeStep(1);
      return;
    }
  };

  useEffect(() => {
    handleServerValidationStepRedirection();
    return () => {};
  }, [props.workTimingsServerValidation]);

  const { t } = useTranslation();

  const steps = ["sign in", "break settings", "sign out", "sign in settings"];

  return (
    <>
      <Stepper
        activeStep={props.active}
        alternativeLabel
        classes={{
          alternativeLabel: classes.alternativeLabel,
          root: classes.root,
        }}
        connector={
          <StepConnector
            sx={{
              ".MuiStepConnector-line": {
                borderColor: "#23aaeb85",
                borderTopWidth: 2,
              },
            }}
          />
        }
      >
        {steps.map((label, i) => (
          <Step key={label} onClick={() => props.onChangeStep(i)}>
            <StepLabel
              classes={{
                label: classes.label,
                completed: classes.completed,
                active: classes.active,
                iconContainer: classes.iconContainer,
              }}
              StepIconProps={{
                classes: {
                  completed: false ? classes.errorCompleted : classes.completed,
                  active: false ? classes.errorActive : classes.active,
                },
              }}
              // error={true}
            >
              <span role="button" onClick={() => props.onChangeStep(i)}>
                {t(label)}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workTimingsServerValidation: state.super.workTimingsServerValidation,
  };
};

export default connect(mapStateToProps)(WorkTimingsTab);
