import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { DateTimePickerForm, InputForm } from "form-builder";
import {
  addOfficeHolidayAction,
  resetOfficeHolidayAction,
  dismissOfficeHolidayModalAction,
  addAttendanceProfileHolidayAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = `holidayForm`;
const formNameValidation = `holidayFormValidation`;

const AddHolidayModal = (props) => {

  const { t } = useTranslation();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const handleSubmitHolidayForm = () => {
    setFormSubmitting(true);
    switch (props.type) {
      case "office":
        if (!props.formValidations.length) {
          props.addOfficeHolidayAction({
            ...props.formData,
          });
          setFormSubmitting(false);
        }
        break;
      case "att_profile":
        if (!props.formValidations.length) {
          props.addAttendanceProfileHolidayAction({
            ...props.formData,
          });
          setFormSubmitting(false);
        }
        break;

      default:
        return;
    }

    return;
  };
  const toggleModal = () => {
    props.dismissOfficeHolidayModalAction({
      ref: "officeHolidayModalActions",
      formName,
    });
    props.resetOfficeHolidayAction();
    setFormSubmitting(false);
  };

  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
  };

  return (
    <Modal
      isOpen={props.modalOptions.isVissible}
      toggle={toggleModal}
      centered
      fade={false}
    >
      <ModalHeader toggle={toggleModal}>{t("add holiday")}</ModalHeader>

      <ModalBody>
        <InputForm
          {...FormProps}
          validateBy="textRequired"
          name="name"
          type="text"
          placeholder={t("name")}
          labelStyle="mb-2"
          containerStyle="mt-0 mb-2"
          inputContainerStyle=" "
        />

        <div className="row mb-3">
          <div className="col-6">
            <DateTimePickerForm
              {...FormProps}
              containerStyle="custom_datepicker_wrapper_style"
              name="start_date"
              validateBy="textRequired"
              label="start date"
              labelStyle="mb-2"
              hasIcon
              minDate={new Date()}
              requestFormat="YYYY-MM-DD"
            />
          </div>

          <div className="col-6">
            <DateTimePickerForm
              {...FormProps}
              containerStyle="custom_datepicker_wrapper_style"
              name="end_date"
              label="end date"
              labelStyle="mb-2"
              validateBy="end_date"
              dependTime="start_date"
              hasIcon
              minDate={new Date()}
              requestFormat="YYYY-MM-DD"
            />
          </div>
        </div>

        <div className="mt-1 mb-1 text-right">
          <button
            type="submit"
            onClick={handleSubmitHolidayForm}
            className="btn btn-primary submit_btn_style"
          >
            {t("save")}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    formData: state.super[formName],
    formValidations: state.super[formNameValidation],
    modalOptions: state.super.officeHolidayModalActions,
  };
};

export default connect(mapStateToProps, {
  addOfficeHolidayAction,
  resetOfficeHolidayAction,
  dismissOfficeHolidayModalAction,
  addAttendanceProfileHolidayAction,
})(AddHolidayModal);
