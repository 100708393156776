import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { BSelect, InputForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissConfirmDeleteManagerModalAction,
  deleteUserAttempt,
  onInputResetAction,
  fetchReplaceByManagersOptsAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";

const formName = "confirmDeleteManagerModal";
const formNameValidation = "confirmDeleteManagerModalValidation";
const formServerValidation = "confirmDeleteManagerModalServerValidation";

const ConfirmDeleteManagerModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (props.modalValidation.length) {
      return;
    }

    props.deleteUserAttempt(
      props?.confirmDeleteManagerModal?.id,
      props?.confirmDeleteManagerModal?.password,
      props?.confirmDeleteManagerModal?.replace_by,
      props?.confirmDeleteManagerModal?.new_manager_for_replaced_by
    );
  };

  const toggleModal = () => {
    props.dismissConfirmDeleteManagerModalAction({
      ref: "confirmDeleteManagerModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
    // to fetch replace by manager options
    if (props?.isModalVissible) {
      props.fetchReplaceByManagersOptsAction("inConfirmDeleteManagerModal");
    }
  }, [props?.isModalVissible]);

  const { managedEmployees } = props?.confirmDeleteManagerModal;

  return (
    <MainModal
      isOpen={props?.isModalVissible}
      toggle={toggleModal}
      modalTitle={t("Delete Manager")}
      btnLabel={t("Confirm, delete the manager")}
      btnLabelCancel={t("Cancel")}
      btnOnClick={handleSubmitBtn}
      btnOnCancelClick={toggleModal}
      btnSubmitLoading={props?.isLoading}
    >
      <>
        {props?.isReplaceByManagersOptionsLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        <div className="row mb-2">
          <div className="col-12">
            <BSelect
              label="replace by"
              labelStyle="mb-2"
              name="replace_by"
              {...FormProps}
              validationName={`replace_by`}
              options={props?.replaceByManagersOptions}
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              placeholder={t("select manager")}
              isClearable
              optDependType={"notEqual"}
              optDependValue={[props?.confirmDeleteManagerModal?.id]}
              optDependKey="id"
            />
          </div>
        </div>

        {managedEmployees
          ?.map((emp) => emp?.id)
          ?.includes(props?.confirmDeleteManagerModal?.replace_by) ||
        !!props?.confirmDeleteManagerModal?.new_manager_for_replaced_by ? (
          <div className="row mb-2">
            <div className="col-12">
              <BSelect
                name="new_manager_for_replaced_by"
                {...FormProps}
                validationName={`new_manager_for_replaced_by`}
                options={props?.replaceByManagersOptions}
                keepDefaultStyle
                optionLabel="name"
                optionValue="id"
                placeholder={t("select manager")}
                isClearable
                optDependType={"notEqual"}
                optDependValue={[
                  props?.confirmDeleteManagerModal?.id,
                  props?.confirmDeleteManagerModal?.replace_by,
                ]}
                optDependKey="id"
              />

              <span className="danger_note_style mt-2 d-inline-block">
                <strong>{t("Note")} : </strong>
                {t("new_manager_note_message", {
                  manager_name:
                    props?.confirmDeleteManagerModal?.managerName.toLowerCase(),
                  replace_by_name: props?.replaceByManagersOptions
                    .filter(
                      (replaceName) =>
                        replaceName.id ==
                        props?.confirmDeleteManagerModal?.replace_by
                    )[0]
                    ?.name.toLowerCase(),
                })}
              </span>
            </div>
          </div>
        ) : null}

        <div className="row my-2">
          <div className="col-12">
            <InputForm
              label="password"
              labelStyle="mb-2"
              {...FormProps}
              name="password"
              validationName={`password`}
              containerStyle=""
              inputContainerStyle="w-100"
              type="password"
              placeholder={t("Type your password")}
            />
          </div>
        </div>

        {/* (Start) Error Message */}
        {props?.modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{props?.modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.confirmDeleteManagerModalActions,
  modalValidation: state.super[formNameValidation],
  confirmDeleteManagerModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
  replaceByManagersOptions: state.super.replaceByManagersOptions,
  isModalVissible: state.super.confirmDeleteManagerModalActions?.isVissible,
  isLoading: state.super.confirmDeleteManagerModalActions?.isLoading,
  modalMessage: state.super.confirmDeleteManagerModalActions?.modalMessage,
  isReplaceByManagersOptionsLoading:
    state?.super?.isReplaceByManagersOptionsLoading,
});

export default connect(mapStateToProps, {
  dismissConfirmDeleteManagerModalAction,
  deleteUserAttempt,
  onInputResetAction,
  fetchReplaceByManagersOptsAction,
})(ConfirmDeleteManagerModal);
