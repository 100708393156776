import React, { useEffect, useState } from "react";
import MainModal from "../../MainModal";
import { BSelect } from "form-builder";
import { connect } from "react-redux";
import {
  dismissEditAccountManagersModalAction,
  updateCompanyAccountManagersAction,
  onInputResetWithValueAction,
} from "../../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "editAccountManagersModal";
const formNameValidation = "editAccountManagersModalValidation";
const formServerValidation = "editAccountManagersModalServerValidation";

const EditAccountManagersModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.updateCompanyAccountManagersAction({
      data: props.editAccountManagersModal,
      ref: "editAccountManagersModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissEditAccountManagersModalAction({
      ref: "editAccountManagersModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t("Edit Company Account Managers")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_form_wrapper_style"
    >
      <>
        <div className="row employee-form px-3">
          <div className="col-12 my-0">
            <h4 className="sub-title-style mb-1">{t("Company Info")}</h4>
            <hr />

            <div className="row mt-3 mb-2">
              <div className="col-12 mb-2">
                <BSelect
                  {...FormProps}
                  name="primarySAAS"
                  validationName={`input.primarySAAS`}
                  label="Assign Primary Account Manager"
                  labelStyle="mb-2"
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  options={props?.companyAccountManagers?.filter(
                    (manager) =>
                      !props?.editAccountManagersModal?.secondarySAAS?.includes(
                        manager?.id
                      )
                  )}
                  inputContainerStyle=""
                  placeholder={t("Select Primary Account Manager")}
                  icon="person"
                  validateBy="textRequired"
                />
              </div>
              <div className="col-12 mb-2">
                <BSelect
                  {...FormProps}
                  name="secondarySAAS"
                  validationName={`input.secondarySAAS`}
                  label="Assign Secondary Account Managers"
                  labelStyle="mb-2"
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  options={props?.companyAccountManagers?.filter(
                    (manager) =>
                      manager?.id !==
                      props?.editAccountManagersModal?.primarySAAS
                  )}
                  isMulti={true}
                  inputContainerStyle=""
                  placeholder={t("Select Secondary Account Managers")}
                  icon="person"
                />
              </div>
            </div>
          </div>

          {/* (Start) Error Message */}
          {modalMessage && isSubmitting && (
            <div className="warnig-msg-style">{modalMessage}</div>
          )}
          {/* (End) Error Message */}
        </div>
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.editAccountManagersModalActions,
  modalValidation: state.super[formNameValidation],
  editAccountManagersModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
  companyAccountManagers: state.super.companyAccountManagers,
});

export default connect(mapStateToProps, {
  dismissEditAccountManagersModalAction,
  updateCompanyAccountManagersAction,
  onInputResetWithValueAction,
})(EditAccountManagersModal);
