import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import Loader from "../../Components/Loader";
import {
  showEditHistoryModal,
  fetchEmployeeAttendanceLogsSuccess,
} from "../../Store/Actions";
import { ArrowUpward, ArrowDownward, Home } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import { fetchemployeeAttendanceLogs } from "../../Graphql/query";
import AttendanceLogsExpandedRow from "./AttendanceLogsExpandedRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const dataTableRef = `employeeAttendanceLogs`;

const AttendanceLogs = (props) => {
  const { t } = useTranslation();
  const [getEmployeeHistoryLogs, { loading }] = useLazyQuery(
    fetchemployeeAttendanceLogs,
    {
      variables: {
        id: props.selectedEmployeeProfile?.id,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
      onError(err) {
        console.log("onerror", { err });
      },
      onCompleted: (res) => {
        props.fetchEmployeeAttendanceLogsSuccess(
          "employeeAttendanceLogs",
          res?.employeeAttendanceLogs
        );
      },
    }
  );

  useEffect(() => {
    getEmployeeHistoryLogs();

    return () => {};
  }, []);

  const employeeAttendanceLogsColumns = [
    {
      name: t("date"),
      selector: "date",
      wrap: true,
      grow: 2.1,
      cell: (row) => (
        <div className="attendnace-logs-date">
          {row?.from_home == 1 ? (
            <>
              {moment(row?.date).format("dddd DD-MM-YYYY")}{" "}
              <Home className="home-icon ml-2" />
            </>
          ) : (
            <>{moment(row?.date).format("dddd DD-MM-YYYY")}</>
          )}
        </div>
      ),
    },
    {
      name: t("sign in"),
      selector: "sign_in_time",
      wrap: true,
      grow: 1,
      cell: (row) => (
        <div>
          {row?.sign_in_time != null ? (
            <>
              <ArrowDownward className="green-color mr-2" />
              {moment(row?.sign_in_time).format("hh:mm A")}
            </>
          ) : (
            <>{""}</>
          )}
        </div>
      ),
    },
    {
      name: t("sign out"),
      selector: "sign_out_time",
      wrap: true,
      cell: (row) => (
        <div>
          {row?.sign_out_time != null ? (
            <>
              <ArrowUpward className="red-color mr-2" />
              {moment(row?.sign_out_time).format("hh:mm A")}
            </>
          ) : (
            <>{""}</>
          )}
        </div>
      ),
    },
    {
      name: t("status"),
      selector: "status",
      wrap: true,
      cell: (row) => (
        <div className={`${row?.status}_style`}>
          {t(row?.status?.toLowerCase())}
        </div>
      ),
    },
  ];

  return (
    <div className="col-12">
      <div className="d-flex flex-column flex-xl-row justify-content-between align-items-start">
        <div className="row pl-3 w-75">
          <div
            className="side-title mb-1 py-2"
            onClick={() => getEmployeeHistoryLogs()}
            role="button"
          >
            {t("attendance logs")}
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table employee-att-logs-expand-collapse-wrapper"
        columns={employeeAttendanceLogsColumns}
        data={props?.EmployeeAttendanceLogs}
        noHeader
        persistTableHead
        progressPending={loading}
        progressComponent={<Loader />}
        expandableRows
        expandableRowDisabled={(row) => !row?.multiple_sign_ins?.length} // disabled if only one sign in record
        expandableRowsComponent={<AttendanceLogsExpandedRow />}
        expandableIcon={{
          expanded: <FontAwesomeIcon icon={faAngleUp} />,
          collapsed: <FontAwesomeIcon icon={faAngleDown} />,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    EmployeeAttendanceLogs: state?.super?.employeeAttendanceLogs?.data,
    selectedEmployeeProfile: state?.super?.selectedEmployeeProfile,
    authUser: state?.auth?.userProfile,
  };
};

export default connect(mapStateToProps, {
  showEditHistoryModal,
  fetchEmployeeAttendanceLogsSuccess,
})(AttendanceLogs);
