import React, { useState } from "react";
import { connect } from "react-redux";
import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
} from "form-builder";
import moment from "moment";
import { onInputResetAction } from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import { AccordionSummary, AccordionDetails } from "@mui/material";

const AttSignInSection = (props) => {
  const { t } = useTranslation();

  const { formProps } = props;

  const [extraSettingExpand, setExtraSettingExpand] = useState(false);

  return (
    <>
      <div className={+props.sign_in_req ? "mt-3" : "d-none"}>
        <h4 className="my-2 sub-title-style">{t("sign in")}</h4>

        <div className={""}>
          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            validateBy="textRequired"
            // dependTime="sign_in_end_time"
            label="Minimum Sign In Time"
            name="sign_in_start_time"
            validationName="input.sign_in_start_time"
          />

          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            validateBy="textRequired"
            // dependTime="sign_in_start_time"
            label="Maximum Sign In Time"
            name="sign_in_end_time"
            validationName="input.sign_in_end_time"
          />

          <InputForm
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            validateBy="textRequired"
            label="number of working hours"
            name="work_hours"
            validationName="input.work_hours"
            type="text"
            stepInput
            labelStyle=" "
            inputContainerStyle="m-start-auto"
          />

          <div>
            <DateTimePickerForm
              timeOnly
              {...formProps}
              dependOn="sign_in_req"
              dependancyType="equal"
              dependancyValue={[1]}
              validateBy="textRequired"
              // dependTime="sign_in_start_time"
              label="Employee can sign in as early as"
              name="time_allowed_before_sign_in"
              validationName="input.time_allowed_before_sign_in"
            />
          </div>

          {props.sign_in_start_time &&
          props.time_allowed_before_sign_in &&
          moment(props.sign_in_start_time, "HH:mm").format("A") == "AM" &&
          moment(props.time_allowed_before_sign_in, "HH:mm").format("A") ==
            "PM" ? (
            <CheckboxBooleanForm
              {...formProps}
              validationName="input.consider_permissibility_hours"
              options={["consider permissibility hours"]}
              name="consider_permissibility_hours"
              type="checkbox"
              dependOn="sign_in_req"
              dependancyType="equal"
              dependancyValue={[1]}
              containerStyle="mt-2 mb-1"
              setWithValue
            />
          ) : null}
          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1, "1"]}
            // dependTime="sign_in_end_time"
            // validateBy="end_time"
            label="Employee Cannot Sign Out After"
            name="end_of_day"
            validationName="input.end_of_day"
          />

          <CheckboxBooleanForm
            {...formProps}
            validationName="input.calc_sign_out_time_from_actual_sign_in"
            options={["calculate sign out time from actual sign in"]}
            name="calc_sign_out_time_from_actual_sign_in"
            type="checkbox"
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            containerStyle="mt-2 mb-1"
          />
        </div>
      </div>

      <Accordion
        expanded={extraSettingExpand}
        onChange={() => setExtraSettingExpand(!extraSettingExpand)}
        classes={{
          root: "accordion-none",
        }}
      >
        <AccordionSummary
          classes={{
            root: "p-0 m-0",
          }}
        >
          <span className="font-13">{t("Extra Settings")}</span>
        </AccordionSummary>
        <AccordionDetails>
          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            label="cannot sign in after"
            name="can_not_sign_in_after"
            validationName="input.can_not_sign_in_after"
            isClearable
          />
          <DateTimePickerForm
            timeOnly
            {...formProps}
            dependOn="sign_in_req"
            dependancyType="equal"
            dependancyValue={[1]}
            label="cannot sign out before"
            name="can_not_sign_out_before"
            validationName="input.can_not_sign_out_before"
            isClearable
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state, { formProps = {} }) => {
  return {
    sign_in_req: state.super[formProps?.formName].sign_in_req,
    sign_in_start_time: state.super[formProps?.formName].sign_in_start_time,
    time_allowed_before_sign_in:
      state.super[formProps?.formName].time_allowed_before_sign_in,
  };
};

export default connect(mapStateToProps, { onInputResetAction })(
  AttSignInSection
);
