import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { BSelect } from "form-builder";
import { connect } from "react-redux";
import {
  dismissAdminUserModalAction,
  createAdminUserAction,
  fetchAdminUserEmployeesAction,
  onInputResetAction,
} from "../../Store/Actions";
import Const from "../../Constants";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import SASS from "../../Constants/SassConstants";

const formName = "adminUsersModal";
const formNameValidation = "adminUsersModalValidation";
const formServerValidation = "adminUsersModalServerValidation";

const AdminUserModal = (props) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissAdminUserModalAction({
      ref: "adminUsersModalActions",
      formName,
    });
  };

  // Start of filtering employees select according to selected company id
  const handleEmployeesFilter = () => {
    props.fetchAdminUserEmployeesAction({
      ref: formName,
      [formName]: {
        ...props.adminUsersModal,
      },
    });
  };

  const useDidEmployeesUpdate = useDidUpdateEffect(handleEmployeesFilter, [
    props.adminUsersModal.selectedCompanyFilter,
  ]);
  // End of filtering employees select according to selected company id

  useEffect(() => {
    setIsSubmitting(false);
    return () => {
      props.onInputResetAction(formName, "selectedCompanyFilter");
    };
  }, [isModalVissible]);

  if (props?.isCompaniesLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  const handleSubmitBtn = () => {
    setIsSubmitting(true);

    if (props.modalValidation.length) {
      return;
    }

    props.createAdminUserAction({
      data: props.adminUsersModal,
      ref: "adminUsersModalActions",
    });
  };

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t("Add New Admin User")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
    >
      <>
        {props?.isEmployeesLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        <div className="row">
          <div className="col-12 mb-2">
            <BSelect
              {...FormProps}
              reducer="super"
              name="selectedCompanyFilter"
              label="select company"
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              options={props?.adminUserCompaniesData}
              inputContainerStyle="mt-0"
              placeholder={t("select company")}
              validateBy="textRequired"
              isClearable
            />
          </div>

          <div className="col-12 mb-2">
            <BSelect
              {...FormProps}
              reducer="super"
              name="user_id"
              validationName={`input.user_id`}
              label="Select User"
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              options={props?.adminUserEmployeesData}
              inputContainerStyle="mt-0"
              placeholder={t("select_employee")}
              validateBy="textRequired"
              isClearable
              optDependType="equal"
              optDependKey="saas"
              optDependValue={[null]}
            />
          </div>

          <div className="col-12 mb-2">
            <BSelect
              {...FormProps}
              reducer="super"
              name="role_id"
              validationName={`input.role_id`}
              label="User Role"
              keepDefaultStyle
              optionLabel="label"
              optionValue="value"
              options={Const.userRoleOptions}
              inputContainerStyle="mt-0"
              placeholder={t("Select Role")}
              validateBy="textRequired"
              isClearable
            />
          </div>

          <div className="col-12 mb-2">
            <BSelect
              {...FormProps}
              reducer="super"
              name="company_ids"
              validationName={`input.company_ids`}
              label="Assigned Companies"
              keepDefaultStyle
              optionLabel="name"
              optionValue="id"
              options={props?.adminUserCompaniesData}
              isMulti={true}
              inputContainerStyle="mt-0"
              placeholder={t("select company")}
              dependOn="role_id"
              dependancyType="equal"
              dependancyValue={[SASS.ACCOUNT_MANAGER]}
            />
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalMessage && isSubmitting && (
          <div className="warnig-msg-style">{modalMessage}</div>
        )}
        {/* (End) Error Message */}
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.adminUsersModalActions,
  modalValidation: state.super[formNameValidation],
  adminUsersModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
  adminUserEmployeesData: state.super.adminUserEmployeesData,
  adminUserCompaniesData: state.super.adminUserCompaniesData,
  isCompaniesLoading: state?.super?.adminUserCompaniesLoading,
  isEmployeesLoading: state?.super?.adminUserEmployeesLoading,
});

export default connect(mapStateToProps, {
  dismissAdminUserModalAction,
  createAdminUserAction,
  fetchAdminUserEmployeesAction,
  onInputResetAction,
})(AdminUserModal);
