import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BSelect } from "form-builder";

// Utils
import {
  onFormResetAction,
  showPreviewRolePrivilagesModalAction,
} from "../../../Store/Actions";

// Icons
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import BusinessIcon from "@mui/icons-material/Business";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import PreviewRolePrivilagesModal from "../../../Components/PreviewRolePrivilagesModal";
import { useLazyQuery } from "@apollo/client";
import { GET_RELEVANT_REGULATIONS_AND_STATES } from "../../../Graphql/query";

const OfficeInfoSection = ({ FormProps }) => {
  const { t } = useTranslation();

  // Redux Hooks
  const dispatch = useDispatch();
  const employeeForm = useSelector(
    (state) => state.super?.[FormProps?.formName]
  );
  const isEditing = useSelector((state) => state?.super?.editEmployeeForm);
  const offices = useSelector(
    (state) => state?.super?.editEmployeeFormData?.offices
  );
  const departments = useSelector(
    (state) => state?.super?.editEmployeeFormData?.departments
  );
  const positions = useSelector(
    (state) => state?.super?.editEmployeeFormData?.positions
  );
  const roles = useSelector(
    (state) => state?.super?.editEmployeeFormData?.roles
  );
  const managers = useSelector(
    (state) => state?.super?.editEmployeeFormData?.managers
  );
  const planId = useSelector((state) => state?.auth?.userProfile?.company?.activeSubscription?.plan?.id);
  const isPrivilagesModalOpen = useSelector(
    (state) => state.super.previewRolePrivilagesModalActions.isVissible
  );
  const salaryManagementType = useSelector(
    (state) => state.super?.salaryConfigForm?.salary_management_type
  );


  const selectedOffice = offices?.find(
    (office) => office?.id == employeeForm?.office_id
  );

  const [attemptGetRelevantRegulationsAndState, { loading }] = useLazyQuery(
    GET_RELEVANT_REGULATIONS_AND_STATES,
    {
      onCompleted: (data) => {
        const { relevantRegulationTypes = [], relevantStates = [] } =
          data ?? {};

        dispatch(
          onFormResetAction(
            "userRegulationTypesOptions",
            relevantRegulationTypes?.map((regulation) => ({
              ...regulation,
              isDefault:
                regulation?.id === selectedOffice?.defaultRegulation?.id,
            }))
          )
        );

        dispatch(onFormResetAction("relevantStates", relevantStates));
      },
    }
  );

  useEffect(() => {
    if (!!employeeForm?.office_id && !!!salaryManagementType) {
      attemptGetRelevantRegulationsAndState({
        variables: {
          countryId: selectedOffice?.country?.id,
        },
      });
    }
  }, [employeeForm?.office_id, salaryManagementType]);

  // Start of show preview role privilages modal function
  const handleShowPreviewRolePrivilagesModal = () => {
    let data = {
      planId: planId,
      roleId: employeeForm?.role_id,
    };

    dispatch(
      showPreviewRolePrivilagesModalAction({
        data,
        ref: "previewRolePrivilagesModalActions",
        formName: "previewRolePrivilagesModal",
      })
    );
  };
  // End of show preview role privilages modal function

  return (
    <div className="boxContainer">
      <div className="name_container">
        <InsertDriveFileIcon />
        <h4 className="sub-title-style mb-0">{t("office info")}</h4>
      </div>
      <hr />
      <div className="row mt-3 mb-2">
        <div className="col-md-12 ">
          <BSelect
            {...FormProps}
            name="office_id"
            label={t("office")}
            keepDefaultStyle
            placeholder={t("select option")}
            options={offices}
            validateBy="textRequired"
            validationName="input.user_input.office_id"
            icon={<BusinessIcon />}
            skipLocalization
          />
        </div>

        <div className="col-md-6 mt-2">
          <BSelect
            {...FormProps}
            name="department_id"
            label={t("department")}
            keepDefaultStyle
            placeholder={t("select department")}
            options={departments}
            validateBy="textRequired"
            validationName="input.user_input.department_id"
            icon={<LocationCityIcon />}
          />
        </div>

        <div className="col-md-6 mt-2">
          <BSelect
            {...FormProps}
            name="position_id"
            label={t("position")}
            keepDefaultStyle
            placeholder={t("select option")}
            options={positions}
            validateBy="textRequired"
            validationName="input.user_input.position_id"
            icon={<PersonOutlineIcon />}
          />
        </div>

        {!isEditing ? (
          <div className="col-md-6 mt-2">
            <div className="d-flex align-items-end">
              <div className="w-100">
                <BSelect
                  {...FormProps}
                  name="role_id"
                  label={t("role")}
                  keepDefaultStyle
                  placeholder={t("select option")}
                  options={roles}
                  validateBy="textRequired"
                  validationName="input.user_input.role_id"
                  icon={<VpnKeyIcon />}
                />
              </div>

              {employeeForm?.role_id ? (
                <div
                  className="btn px-0 mx-2"
                  onClick={handleShowPreviewRolePrivilagesModal}
                >
                  <FontAwesomeIcon
                    className="role_preview_icon_style"
                    icon={faEye}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Start of Preview Role Privilages Modal */}
            {isPrivilagesModalOpen ? <PreviewRolePrivilagesModal /> : null}
            {/* End of Preview Role Privilages Modal */}
          </div>
        ) : null}

        <div className="col-md-6 mt-2">
          <BSelect
            {...FormProps}
            name="manager_id"
            label={t("direct manager")}
            keepDefaultStyle
            placeholder={t("select option")}
            options={managers}
            validateBy="textRequired"
            optDependType="notEqual"
            optDependKey="id"
            optDependValue={
              [...employeeForm?.copied_managers, employeeForm?.id ?? 0] ?? [
                employeeForm?.id ?? 0,
              ]
            }
            validationName="input.user_input.manager_id"
            icon={<PersonOutlineIcon />}
          />
        </div>

        <div className="col-md-6 mt-2">
          <BSelect
            {...FormProps}
            label={t("copied manager")}
            name="copied_managers"
            keepDefaultStyle
            placeholder={t("select option")}
            hideSelectedOptions
            isMulti
            options={managers}
            optDependType="notEqual"
            optDependKey="id"
            optDependValue={[employeeForm?.manager_id, employeeForm?.id ?? 0]}
            validationName="input.user_input.copied_managers"
            icon={<PersonOutlineIcon />}
          />
        </div>
      </div>
    </div>
  );
};

export default OfficeInfoSection;
