import React, { useEffect, useState } from "react";
import MainModal from "../../MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  RadioboxForm,
} from "form-builder";
import { connect } from "react-redux";
import {
  dismissAddSubscriptionModalAction,
  upsertCompanySubscriptionAction,
  onInputResetAction,
  updateEmployeeLimitValueAction,
} from "../../../Store/Actions";
import { useTranslation } from "react-i18next";
import usePrevious from "../../../Helpers/Hooks/usePrevious";
import { useQuery } from "@apollo/client";
import { getActiveSaasUsersMenu } from "../../../Graphql/query";
import { showToast } from "../../../Helpers/HelperFns";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../Constants/Privilages";

const formName = "addSubscriptionModal";
const formNameValidation = "addSubscriptionModalValidation";
const formServerValidation = "addSubscriptionModalServerValidation";

const AddSubscriptionModal = (props) => {
  const isEdit = Boolean(props?.addSubscriptionModal?.id);
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    const { client_at, contract_duration, contract_amount, ...subscription } =
      props.addSubscriptionModal;

    const data = {
      ...subscription,
      ...(props?.addSubscriptionModal?.canEditSubcriptionClientStatus &&
        props.addSubscriptionModal?.company_status === "client" && {
          client_at,
          contract_amount: +contract_amount,
          contract_duration: +contract_duration,
        }),
    };

    props.upsertCompanySubscriptionAction({
      data,
      ref: "addSubscriptionModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissAddSubscriptionModalAction({
      ref: "addSubscriptionModalActions",
      formName,
    });
  };

  // to get the previous values using usePrevious hook
  const prevPlanId = usePrevious(props?.addSubscriptionModal?.plan_id);

  // handle employees limit according to plan selected
  useEffect(() => {
    if (prevPlanId && prevPlanId !== props.addSubscriptionModal?.plan_id) {
      props.updateEmployeeLimitValueAction(
        props?.companySubscriptionPlans
          ?.filter(
            (plan) => plan?.id === props?.addSubscriptionModal?.plan_id
          )?.[0]
          ?.features?.filter((feature) => feature?.id === "1")[0]?.pivot
          ?.limits,
        formName
      );
    }
  }, [props?.addSubscriptionModal?.plan_id]);

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  const [options, setOptions] = useState({ agents: [] });
  const { loading: isOptionsLoading } = useQuery(getActiveSaasUsersMenu, {
    onCompleted: ({ users }) => {
      setOptions({
        agents: users || [],
      });
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });
  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t(
        `${
          props?.addSubscriptionModal?.id != null ? "Edit" : "Add"
        } Subscription`
      )}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_form_wrapper_style"
    >
      <>
        <div className="row employee-form px-3">
          <div className="col-12 my-0">
            <h4 className="sub-title-style mb-1">{t("Subscription")}</h4>
            <hr />

            <div className="row mt-3 mb-2">
              {(
                isEdit
                  ? props?.addSubscriptionModal?.isActive ||
                    props?.addSubscriptionModal?.future_subscription_check
                  : true
              ) ? (
                <>
                  <div className="col-12 col-md-6 mb-2">
                  <HasPrivileges
                    reqireMain={[
                      Privilages.SUPER_PRIVILEGE,
                      Privilages.EDIT_SUBSCRIPTION_CREATED_BY,
                    ]}>
                    <BSelect
                      {...FormProps}
                      name="created_by_agent_id"
                      validationName={`input.created_by_agent_id`}
                      label="created by"
                      labelStyle="mb-2"
                      keepDefaultStyle
                      optionLabel="name"
                      optionValue="id"
                      options={options?.agents}
                      inputContainerStyle=""
                      placeholder={t("select agent")}
                      icon="user"
                    />
                    </HasPrivileges>
                    <BSelect
                      {...FormProps}
                      name="plan_id"
                      validateBy="textRequired"
                      validationName={`input.plan_id`}
                      label="Plan"
                      labelStyle="mb-2"
                      keepDefaultStyle
                      optionLabel="name"
                      optionValue="id"
                      options={props?.companySubscriptionPlans}
                      inputContainerStyle=""
                      placeholder={t("Select Plan")}
                      getOptionLabel={(option) =>
                        `${option?.name} - ${
                          option?.trial_value == null ? t("free") : t("paid")
                        }`
                      }
                      icon="type"
                    />
                  </div>

                  <div className="col-12 col-md-6 mb-2">
                    <InputForm
                      {...FormProps}
                      type="number"
                      validateBy="textRequired"
                      name="employees_limit"
                      validationName={`input.employees_limit`}
                      placeholder={t("Enter limit")}
                      label="Employees limit"
                      labelStyle="mb-2"
                      containerStyle="mt-0"
                      inputContainerStyle=" "
                    />
                  </div>

                  {
                    false ? null : (
                      <>
                        <div className="col-12 col-md-6 mb-2">
                          <BSelect
                            {...FormProps}
                            name="currency_id"
                            validateBy="textRequired"
                            validationName={`input.currency_id`}
                            label="Currency"
                            labelStyle="mb-2"
                            keepDefaultStyle
                            optionLabel="name"
                            optionValue="id"
                            options={props?.companyCurrency}
                            inputContainerStyle=""
                            placeholder={t("select currency")}
                            icon="currency"
                          />
                        </div>

                        <div className="col-12 col-md-6 mb-2">
                          <InputForm
                            {...FormProps}
                            type="number"
                            validateBy="textRequired"
                            name="payment_amount"
                            validationName={`input.payment_amount`}
                            placeholder={t("Enter Amount")}
                            label="Payable Amount"
                            labelStyle="mb-2"
                            containerStyle="mt-0"
                            inputContainerStyle=" "
                            icon="money"
                          />
                        </div>
                      </>
                    )
                  }

                  <div className="col-12 col-md-6 mb-2">
                    <DateTimePickerForm
                      {...FormProps}
                      containerStyle="custom_datepicker_wrapper_style"
                      name="start_date"
                      validateBy="textRequired"
                      validationName={`input.start_date`}
                      label="start date"
                      labelStyle="mb-2"
                      hasIcon
                      minDate={new Date()}
                      requestFormat="yyyy-MM-DD"
                    />
                  </div>

                  <div className="col-12 col-md-6 mb-2">
                    <DateTimePickerForm
                      {...FormProps}
                      containerStyle="custom_datepicker_wrapper_style"
                      name="end_date"
                      label="end date"
                      labelStyle="mb-2"
                      validationName={`input.end_date`}
                      validateBy="end_date"
                      dependTime="start_date"
                      hasIcon
                      minDate={new Date()}
                      requestFormat="yyyy-MM-DD"
                    />
                  </div>
                </>
              ) : null}

              {props?.addSubscriptionModal?.canEditSubcriptionClientStatus ? (
                <>
                  <div className="col-12 col-md-6 mb-2">
                    <RadioboxForm
                      {...FormProps}
                      options={[
                        { label: "Trial", value: "trial" },
                        { label: "Client", value: "client" },
                      ]}
                      labelStyle="d-block font-weight-bold gray-color"
                      optionsContainerStyle="penalty-days-style fit-width flex-md-row flex-column"
                      optionItemStyle=" "
                      optionLabelStyle="mb-0 mr-3"
                      optionInputStyle=" "
                      containerStyle="d-flex flex-wrap align-items-center gap-20 my-2"
                      label="subscription status"
                      name="company_status"
                      validationName="input.company_status"
                    />
                  </div>
                  <DateTimePickerForm
                    {...FormProps}
                    rootStyle="flex-1"
                    containerStyle=" "
                    name="client_at"
                    label="client at"
                    labelStyle="mb-2"
                    validationName={`input.subscription_input.client_at`}
                    hasIcon
                    requestFormat="yyyy-MM-DD"
                    dependOn="company_status"
                    dependancyType="equal"
                    dependancyValue={["client"]}
                  />
                  <div className="col-12 d-flex gap-10">
                    <InputForm
                      {...FormProps}
                      name="contract_duration"
                      validateBy="textRequired"
                      validationName={`input.contract_duration`}
                      label="contract months duration"
                      labelStyle="mb-2"
                      rootStyle="flex-1"
                      containerStyle="mt-0"
                      inputContainerStyle=" "
                      placeholder={t("enter contract months duration")}
                      icon="time"
                      dependOn="company_status"
                      dependancyType="equal"
                      dependancyValue={["client"]}
                    />
                    <InputForm
                      {...FormProps}
                      name="contract_amount"
                      validateBy="textRequired"
                      validationName={`input.contract_amount`}
                      label="contract amount"
                      labelStyle="mb-2"
                      containerStyle="mt-0"
                      rootStyle="flex-1"
                      inputContainerStyle=" "
                      placeholder={t("enter contract amount")}
                      icon="money"
                      dependOn="company_status"
                      dependancyType="equal"
                      dependancyValue={["client"]}
                    />
                  </div>
                </>
              ) : (
                <label className="d-block font-weight-bold gray-color">
                  Subscription Status :
                  {props?.addSubscriptionModal?.company_status}
                </label>
              )}
            </div>
          </div>

          {/* (Start) Error Message */}
          {modalMessage && isSubmitting && (
            <div className="warnig-msg-style">{modalMessage}</div>
          )}
          {/* (End) Error Message */}
        </div>
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.addSubscriptionModalActions,
  modalValidation: state.super[formNameValidation],
  addSubscriptionModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
  companyCurrency: state.super.companyCurrency,
  companySubscriptionPlans: state.super.companySubscriptionPlans,
});

export default connect(mapStateToProps, {
  dismissAddSubscriptionModalAction,
  upsertCompanySubscriptionAction,
  onInputResetAction,
  updateEmployeeLimitValueAction,
})(AddSubscriptionModal);
