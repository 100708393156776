import React, { useEffect } from "react";
import { connect } from "react-redux";
import { CheckboxBooleanForm } from "form-builder";
import MainModal from "../../Components/MainModal";
import { toggleYearlyReportCheckbox } from "../../Store/Actions";
import { useTranslation } from "react-i18next";

const formName = "yearlyReportColumns";

const ToggleModal = (props) => {
  const { t } = useTranslation();

  const { isModalVissible, toggleModal } = props;

  const activeCheckBoxes = (ref) => {
    props?.toggleYearlyReportCheckbox({
      ref,
      value: 1,
    });
  };

  useEffect(() => {
    // (Start) Handle Over Time Duration
    if (
      !props?.reportColumns?.overtime_days &&
      !props?.reportColumns?.accepted_overtime_hours
    ) {
      activeCheckBoxes("accepted_overtime_hours");
    }
    // (End) Handle Over Time Duration
    // (Start) Handle Premissions Duration
    if (
      !props?.reportColumns?.permissions &&
      !props?.reportColumns?.permissions_time
    ) {
      activeCheckBoxes("permissions_time");
    }
    // (End) Handle Premissions Duration
    // (Start) Handle Breaks Duration
    if (!props?.reportColumns?.breaks && !props?.reportColumns?.breaks_time) {
      activeCheckBoxes("breaks_time");
    }
    // (End) Handle Breaks Duration
  }, [
    props?.reportColumns?.overtime,
    props?.reportColumns?.permissionsColumn,
    props?.reportColumns?.breaksColumn,
  ]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t("toggle columns")}
      btnLabel={t("close")}
      btnOnClick={toggleModal}
      btnSubmitLoading={props?.isLoading}
    >
      <div className="row">
        <div className="col-6">
          {/* (Start) Offices */}
          <CheckboxBooleanForm
            formName={formName}
            options={["offices"]}
            name="office"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) Offices */}

          {/* (Start) Departments */}
          <CheckboxBooleanForm
            formName={formName}
            options={["departments"]}
            name="department"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) Departments */}

          {/* (Start) Positions */}
          <CheckboxBooleanForm
            formName={formName}
            options={["positions"]}
            name="position"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) Positions */}

          <CheckboxBooleanForm
            formName={formName}
            options={["employee code"]}
            name="emp_code"
            type="checkbox"
            reducer="monthlyReport"
          />

          {/* (Start) Holidays */}
          <CheckboxBooleanForm
            formName={formName}
            options={["holidays"]}
            name="holidays"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) Holidays */}

          {/* (Start) Weekends */}
          <CheckboxBooleanForm
            formName={formName}
            options={["weekends"]}
            name="weekends"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) Weekends */}

          {/* (Start) Total Days Attended */}
          <CheckboxBooleanForm
            formName={formName}
            options={["total days attended"]}
            name="attended_days"
            type="checkbox"
            reducer="monthlyReport"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="attended_days"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["days ontime"]}
              name="ontime"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <div className="col-12 mx-2">
              <CheckboxBooleanForm
                dependOn="ontime"
                dependancyType="equal"
                dependancyValue={[1]}
                formName={formName}
                options={["total days"]}
                name="ontime_total"
                type="checkbox"
                resetValue={0}
                reducer="monthlyReport"
              />
              <CheckboxBooleanForm
                dependOn="ontime"
                dependancyType="equal"
                dependancyValue={[1]}
                formName={formName}
                options={["normal days_"]}
                name="ontime_normal"
                type="checkbox"
                resetValue={0}
                reducer="monthlyReport"
              />
              <CheckboxBooleanForm
                dependOn="ontime"
                dependancyType="equal"
                dependancyValue={[1]}
                formName={formName}
                options={["half-days"]}
                name="ontime_half"
                type="checkbox"
                resetValue={0}
                reducer="monthlyReport"
              />
            </div>
            <CheckboxBooleanForm
              dependOn="attended_days"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["days late"]}
              name="late"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <div className="col-12 mx-2">
              <CheckboxBooleanForm
                dependOn="late"
                dependancyType="equal"
                dependancyValue={[1]}
                formName={formName}
                options={["total days"]}
                name="late_total"
                type="checkbox"
                resetValue={0}
                reducer="monthlyReport"
              />
              <CheckboxBooleanForm
                dependOn="late"
                dependancyType="equal"
                dependancyValue={[1]}
                formName={formName}
                options={["normal days_"]}
                name="late_normal"
                type="checkbox"
                resetValue={0}
                reducer="monthlyReport"
              />
              <CheckboxBooleanForm
                dependOn="late"
                dependancyType="equal"
                dependancyValue={[1]}
                formName={formName}
                options={["half-days"]}
                name="late_half"
                type="checkbox"
                resetValue={0}
                reducer="monthlyReport"
              />
            </div>
          </div>
          {/* (End) Total Days Attended */}

          {/* (Start) Absent */}
          <CheckboxBooleanForm
            formName={formName}
            options={["absent"]}
            name="absent_total"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) Absent */}

          {/* (Start) Leaves */}
          <CheckboxBooleanForm
            formName={formName}
            options={["leaves"]}
            name="leaves"
            type="checkbox"
            reducer="monthlyReport"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["normal leaves"]}
              name="normal_leaves"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["emergency leaves"]}
              name="emergency_leaves"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["sick leaves"]}
              name="sick_leaves"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["unpaid leaves"]}
              name="unpaid_leaves"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <CheckboxBooleanForm
              dependOn="leaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["half-days"]}
              name="half_days"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
          </div>
          {/* (End) Leaves */}
        </div>

        <div className="col-6">
          {/* (Start) Remaining Leaves */}
          <CheckboxBooleanForm
            formName={formName}
            options={["remaining leaves"]}
            name="remaningLeaves"
            type="checkbox"
            reducer="monthlyReport"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="remaningLeaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["normal leaves"]}
              name="remaining_annual_leaves"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <CheckboxBooleanForm
              dependOn="remaningLeaves"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["emergency leaves"]}
              name="remaining_emergency_credit"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
          </div>
          {/* (End) Remaining Leaves */}

          {/* (Start) check-ins hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["Check-in Hours"]}
            name="total_checkIn_hours_secs"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) check-ins hours */}

          {/* (Start) Overtime */}
          <CheckboxBooleanForm
            formName={formName}
            options={["overtime"]}
            name="overtime"
            type="checkbox"
            reducer="monthlyReport"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="overtime"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["number of days"]}
              name="overtime_days"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <CheckboxBooleanForm
              dependOn="overtime"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["overtime duration"]}
              name="accepted_overtime_hours"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
              disabled={!props?.reportColumns?.overtime_days}
            />
          </div>
          {/* (End) Overtime */}

          {/* (Start) permissions */}
          <CheckboxBooleanForm
            formName={formName}
            options={["permissions"]}
            name="permissionsColumn"
            type="checkbox"
            reducer="monthlyReport"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="permissionsColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["number of permissions"]}
              name="permissions"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <CheckboxBooleanForm
              dependOn="permissionsColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["permission duration"]}
              name="permissions_time"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
              disabled={!props?.reportColumns?.permissions}
            />
          </div>
          {/* (End) permissions */}

          {/* (Start) breaks */}
          <CheckboxBooleanForm
            formName={formName}
            options={["breaks"]}
            name="breaksColumn"
            type="checkbox"
            reducer="monthlyReport"
          />
          <div className="col-12 mx-2">
            <CheckboxBooleanForm
              dependOn="breaksColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["number of breaks"]}
              name="breaks"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
            />
            <CheckboxBooleanForm
              dependOn="breaksColumn"
              dependancyType="equal"
              dependancyValue={[1]}
              formName={formName}
              options={["break duration"]}
              name="breaks_time"
              type="checkbox"
              resetValue={0}
              reducer="monthlyReport"
              disabled={!props?.reportColumns?.breaks}
            />
          </div>
          {/* (End) breaks */}

          {/* (Start) Total Work Hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["total hrs worked"]}
            name="total_hours_secs"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) Total Work Hours */}

          {/* (Start) Expected Work Hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["expected work hrs"]}
            name="expected_hours_secs"
            type="checkbox"
            reducer="monthlyReport"
          />
          {/* (End) Expected Work Hours */}
          <CheckboxBooleanForm
            formName={formName}
            options={["total early sign out time"]}
            name="total_early_sign_out_time"
            type="checkbox"
            reducer="monthlyReport"
          />
          <CheckboxBooleanForm
            formName={formName}
            options={["total penalties as days"]}
            name="total_penalties_as_days"
            type="checkbox"
            reducer="monthlyReport"
          />
          <CheckboxBooleanForm
            formName={formName}
            options={["total penalties as fixed amount"]}
            name="total_penalties_as_fixed_amount"
            type="checkbox"
            reducer="monthlyReport"
          />
          <CheckboxBooleanForm
            formName={formName}
            options={["total penalties as hours"]}
            name="total_penalties_as_hours"
            type="checkbox"
            reducer="monthlyReport"
          />

          <CheckboxBooleanForm
            formName={formName}
            options={["total overtime hours"]}
            name="total_over_time_hours"
            type="checkbox"
            reducer="monthlyReport"
          />
        </div>
      </div>
    </MainModal>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoading: false,
    reportColumns: state.monthlyReport.yearlyReportColumns,
  };
};
export default connect(mapStateToProps, { toggleYearlyReportCheckbox })(
  ToggleModal
);
